import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
	const headers = document.querySelectorAll('.hero-header-blocks');
	headers.forEach((header) => {
		const blocks = header.querySelectorAll('.header-block');
		gsap.from(blocks, {
			duration: 1,
			opacity: 0,
			y: -20,
			stagger: 0.2,
			scrollTrigger: {
				trigger: header,
				start: 'top center',
				end: 'bottom center',
			},
		});
	});
});
